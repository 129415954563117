import { useState, useEffect } from "react";
import './css/style.css'

function Errorpage() { 
    
    return (
        <center>
            <div className="container">
                <div className="row min-vh-100">
                    <div className="col-lg-12 mt-5 pt-5">
                        <h1 className='my-5'>404</h1>
                        <p>Sorry! The page you’re looking for cannot be found.</p>
                        <p>404 is a status code that tells a web user that a requested page is not available.</p>
                    </div>
                </div>
            </div>
        </center>
    )
}

export default Errorpage;