import { useState, useEffect } from "react";
import $ from "jquery";
import './css/style.css'
import { HiArrowDown } from 'react-icons/hi';

import Web3 from 'web3'
import { erc20Abi } from './Abi/erc20abi'
import bscimage from './images/bnb.png'
import dusdimages from './images/dusd.png'
import dchainimg from './images/dchainimages.png'
import axios from 'axios';
import Navigate from "./components/navigate";
import config from './config/config';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import Swal from "sweetalert2";
import { transferbscdcxtoken } from './transferbridge/refertransferbscdcx';
import Select from 'react-select';


import logochangestyle from './css/logochange.module.css'


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '1px dotted pink',
    color: state.isSelected ? 'white' : 'white',
    backgroundColor: state.isFocused ? "#1b9ffe" : "#031228",
  }),
  control: (base, state) => ({
    ...base,
    color: "red",
    fontSize: 14,
    background: "transparent",
    border: 0,
    // This line disable the blue border
    boxShadow: 'none',
    padding: "6px",
    '&:hover': {
      borderColor: "#ffffff54"
    },

  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    border: '1px solid #3989f92d',
    marginTop: 0
  }),
  menuList: (base, state) => ({
    ...base,
   padding:0,
  })
}




function Referral() {


  const [isActive,setisActive] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const [depositamount, setdepositamount] = useState(0);
  const [dropdownvalue, setdropdownvalue] = useState("");
  const [bridgesoption, setbridgesoption] = useState([
    { value: 1, label: "BNB" },
    { value: 2, label: "USDT (BEP20)" }
  ]);

  const [tobridgesoption, settobridgesoption] = useState([
    { value: 1, label: "DCX" },
    { value: 2, label: "DUSD" }
  ]);

  const [accountaddress, setaccountaddress] = useState("");
  const [selecttoken, setselecttoken] = useState("0");
  const [tokenbalance, settokenbalance] = useState("0");
  const [tokensymbol, settokensymbol] = useState("BNB");
  const [networklogo, setnetworklogo] = useState(bscimage);

  const [bscbal, setbscbal] = useState('0');

  const [bridgenetworklogo, setbridgenetworklogo] = useState(dchainimg);
  const [bridgenetworksymbol, setbridgenetworksymbol] = useState('DCX');
  const [network, setnetwork] = useState();
  const [dcxamountvalue, setdcxamountvalue] = useState(0);
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState("")
  const [ReferralUserAddress, setReferralUserAddress] = useState("");

  const [todropdownvalue, settodropdownvalue] = useState("");

  const [ip, setIP] = useState('');
  const [usdchangeran, setusdchangeran] = useState();

  const getIP = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
  }


  const torewardsetvalue = async (event) => {
    settodropdownvalue(event.label)
    setisActive(true)
    const userAddress = sessionStorage.getItem("chainaccounts")
    var web3dchainjs = new Web3(config.dchainrpcurl);
    var web3dchainmainjs = new Web3(config.dchainrpcurl);

    if (event.label == "DUSD") {
      setbridgenetworklogo(dusdimages)
      setbridgenetworksymbol("DUSD")
      setusdchangeran(1)
      const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.DUSD_TOKEN_ADDRESS);
      const dchainbalance = await dchaincontract.methods.balanceOf(userAddress).call();
      const balancesdchain = web3dchainjs.utils.fromWei(dchainbalance, 'ether');
      const balancesdchainfixed = Number(balancesdchain).toFixed(8)
      setbscbal(balancesdchainfixed)
      changefromvalues(depositamount, 1)
    }
    else {
      setbridgenetworklogo(dchainimg)
      setbridgenetworksymbol("DCX")
      const usd = 0.5;
      setusdchangeran(usd)
      const dchainbalance = await web3dchainmainjs.eth.getBalance(userAddress);
      const balancesdchain = web3dchainmainjs.utils.fromWei(dchainbalance, "ether");
      const balancesdchainfixed = Number(balancesdchain).toFixed(8)
      setbscbal(balancesdchainfixed)
     
      changefromvalues(depositamount, usd)
    }
  }

  const torewardset = async (event) => {
    settodropdownvalue(event)
    setisActive(true)
    const userAddress = sessionStorage.getItem("chainaccounts")
    var web3dchainmainjs = new Web3(config.dchainrpcurl);
   
      setbridgenetworklogo(dchainimg)
      setbridgenetworksymbol("DCX")
      const dchainbalance = await web3dchainmainjs.eth.getBalance(userAddress);
      const balancesdchain = web3dchainmainjs.utils.fromWei(dchainbalance, "ether");
      const balancesdchainfixed = Number(balancesdchain).toFixed(8)
      setbscbal(balancesdchainfixed)
      
      // const getPrice = await axios.get(config.DEXTRADE_API);
      // const currentprice = getPrice.data.data.last;
      // const usd = currentprice
      const usd = 0.5;
      changefromvalues(depositamount, usd)
      if (usd > 0) {
        setusdchangeran(usd)
      } else{
        setusdchangeran(0)
      }
  }


  const rewardset = async (event) => {
    setdropdownvalue(event.label)
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const chainid = await window.ethereum.request({ method: "eth_chainId" });
    sessionStorage.setItem('chainaccounts', accounts[0])
    if (chainid == config.BNB_CHAINID) {
      if (event.label == "BNB") {
        setdepositamount(0)
        settokensymbol("BNB")
        var web3js = new Web3(config.bsctestnetrpcurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balanceseth = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(3);
       
        setselecttoken(event.label)
        settokenbalance(tokenbalance)
        torewardset("DCX")
        setbridgenetworksymbol("DCX")
      }
      else {
        setdepositamount(0)
        settokensymbol("USDT")
        var web3js = new Web3(config.bsctestnetrpcurl);
        const btomfromcontract = new web3js.eth.Contract(erc20Abi, config.BSC_TOKEN_ADDRESS);
        const weibalance = await btomfromcontract.methods.balanceOf(accounts[0]).call();
        const balances = web3js.utils.fromWei(weibalance, 'ether');
        const tokenbalance = parseFloat(balances).toFixed(2);

        setselecttoken(event.label)
        settokenbalance(tokenbalance)
        torewardset("DCX")
        setbridgenetworksymbol("DCX")
      }
    }
    else {
      console.log("wrong network")
    }
  }

  async function bridges() {
    var web3js = new Web3(window.ethereum);
    const chainid = await window.ethereum.request({ method: "eth_chainId" });
    if (chainid == config.BNB_CHAINID) {
      $('.switch-tabs-section-eth').css('background', 'transparent');
      $('.switch-tabs-section-eth').css('color', '#FFF');
      $('.switch-tabs-section-bsc').css('background', '#1b9ffe');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-poly').css('background', 'transparent');
      $('.switch-tabs-section-poly').css('color', '#FFF');
      settokensymbol('BNB')
      setnetworklogo(bscimage)
      const bridgeOptions = [
        { value: 1, label: "BNB" },
        { value: 2, label: "USDT (BEP20)" }
      ]
      setbridgesoption(bridgeOptions)
    }
    else {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3js.utils.toHex(config.BNB_CHAINID) }]
      });
    }
  }


  const getmaxvaluecontract = async (event) => {
    const getPriceBNB = await axios.get(config.DEXTRADE_BNB_API);
    const gettrxusdt = getPriceBNB.data.data.last;
    const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
    if (todropdownvalue != "") {
      if (currentchainId == config.BNB_CHAINID && tokensymbol == "BNB") {
        const trnbscdcoin = ((event * gettrxusdt) / usdchangeran)
        setdcxamountvalue(trnbscdcoin);
          setShow(false)
          setShowError()
      }
      else if (currentchainId == config.BNB_CHAINID && tokensymbol == "USDT") {
        const dcxamount = event / usdchangeran;
        setdcxamountvalue(dcxamount)
          setShow(false)
          setShowError()
      }
      else {
        console.log("something went wrong")
      }
    }
    else {
      toast.error("Please Select Buy Option");
    }
  }

  const gettovaluecontract = async (event) => {
    event.preventDefault()
    setdcxamountvalue(event.target.value);
    const getPriceBNB = await axios.get(config.DEXTRADE_BNB_API);
    const gettrxusdt = getPriceBNB.data.data.last;
    const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
    if (currentchainId == config.BNB_CHAINID && tokensymbol == "BNB") {
      const trnbscdcoin = ((event.target.value * usdchangeran) / gettrxusdt)
      let fromamount = trnbscdcoin.toFixed(5);
      setdepositamount(fromamount.toString())
        setShow(false)
        setShowError()
    }
    else if (currentchainId == config.BNB_CHAINID && tokensymbol == "USDT") {
      const dcxamount = event.target.value * usdchangeran;
      let fromamountusdt = dcxamount.toFixed(5);
      setdepositamount(fromamountusdt.toString());
        setShow(false)
        setShowError()
    }
    else {
      console.log("Something went wrong")
    }
  }

  const changefromvalues = async (values, usdrange) => {
    if (usdrange !== undefined) {
      const getPriceBNB = await axios.get(config.DEXTRADE_BNB_API);
      const gettrxusdt = getPriceBNB.data.data.last;
      const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
      if (currentchainId == config.BNB_CHAINID && tokensymbol == "BNB") {
        const trnbscdcoin = ((values * gettrxusdt) / usdrange)
        setdcxamountvalue(trnbscdcoin.toFixed(5));
          setShow(false)
          setShowError()
      }
      else if (currentchainId == config.BNB_CHAINID && tokensymbol == "USDT") {
        const dcxamount = values / usdrange;
        setdcxamountvalue(dcxamount.toFixed(5))
          setShow(false)
          setShowError()
      }
      else {
        console.log("something went wrong")
      }
    }
  }


  const getfromvaluecontract = async (event) => {
    event.preventDefault()
    setdepositamount(event.target.value)
    if(todropdownvalue !== ""){
      changefromvalues(event.target.value, usdchangeran);
    }
  }


  const transfer = async (e) => {
    var web3js = new Web3(config.dchainrpcurl);
    const balance = await web3js.eth.getBalance(config.DCX_CONTRACT_ADDRESS)
    const balancesdcxs = web3js.utils.toWei(dcxamountvalue.toString(), 'ether');
    const reloadCount = sessionStorage.getItem('chainaccounts');
    if(dropdownvalue == "") {
      toast.error("Select BNB or USDT...");
    }
    else {
      if(todropdownvalue == "DUSD") {
        if (dcxamountvalue === 0) {
          toast.error("Please enter value in amount...");
        } else {
          const ReferralUser = sessionStorage.getItem("referralid");
          let refaddr = ReferralUser;
          const accounts = sessionStorage.getItem("chainaccounts");
          if (window.ethereum.chainId == config.BNB_CHAINID) {
            const setdatas = {
              useraccount: accounts,
              deposits: depositamount,
              tokenselect: selecttoken,
              ipaddr: ip,
              id: id,
              refaddr: refaddr,
              toamountchange: dcxamountvalue,
              totokenvalue: "DUSD"
            };
            await transferbscdcxtoken(setdatas);
          }
          else {
            console.log("wrong network");
          }
        }
      }
      else if(todropdownvalue == "DCX") {
        if(parseInt(balancesdcxs) <= parseInt(balance)) {
          if (dcxamountvalue === 0) {
            toast.error("Please enter value in amount...");
          } else {
            const ReferralUser = sessionStorage.getItem("referralid");
            let refaddr = ReferralUser;
            const accounts = sessionStorage.getItem("chainaccounts");
            if (window.ethereum.chainId == config.BNB_CHAINID) {
              const setdatas = {
                useraccount: accounts,
                deposits: depositamount,
                tokenselect: selecttoken,
                ipaddr: ip,
                id: id,
                refaddr: refaddr,
                toamountchange: dcxamountvalue,
                totokenvalue: "DCX"
              };
              await transferbscdcxtoken(setdatas);
            }
            else {
              console.log("wrong network");
            }
          }
        }
        else {
          Swal.fire({
            icon: "error",
            background: "#041325",
            border: "1px solid #00e5ff21",
            color: "#ffffff",
            html: `
              <div>
                <span>Insufficient balance in Liquidity pool, kindly reach:</span>
                <br />
                <br />
                <a id="copyButton">
                  info@d-ecosystem.io <i class="far fa-copy"></i>
                </a>
              </div>
            `,
          })
          document.getElementById("copyButton").addEventListener("click", function() {
            copyContentToClipboard("info@d-ecosystem.io");
          });
        }
      }
      else {
        toast.error("Select DCX or DUSD");
      }
    }
  }


  const copyContentToClipboard = async(content) => {
    copy(content);
    toast.success("Copied");
  }


  //max value
  const max = async () => {
    const depositpercent = ((tokenbalance * 99) / 100).toString();
    setdepositamount(depositpercent)
    getmaxvaluecontract(depositpercent)
  }


  const getRefAddr = async () => {
    let transferobjects = {
      id: id
    }
    try {
      const getaddr = await axios.post(`${config.APP_API_URL}getrefaddr`, transferobjects);
      setReferralUserAddress(getaddr.data.data.userAddress)
      sessionStorage.setItem('referralid', getaddr.data.data.userAddress)
    } catch (error) {
      navigate('/')
    }
  }


  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };


  useEffect(() => {
    if (window.ethereum) {
      getIP();
      bridges();
      getRefAddr();
      torewardset("DCX")
      const reloadCount = sessionStorage.getItem('chainaccount');
      setaccountaddress(reloadCount)
    }
    else {
      openInNewTab("https://metamask.io/download/")
      toast.warn("Check Metamask is Installed", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, []);

  const handleKeyPress = async (event) => {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  return (
    <div className="App">
      <Navigate useraddress={accountaddress} />

      <section className="py-lg-5 py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 switch-tabs-section">
              <div className="btn-group switch-tabs-btn-group">
                <button type="button"  className="btn switch-tabs-section-bsc">
                  <img className="network-image" src={bscimage} alt="BNB Network" />BUY DCX with BNB or USDT BEP 20
                </button>
              </div>
            </div>
            <div className="col-lg-8 switch-banner-section pb-lg-5 mt-4">
              <p className="switch-banner-section-text-1 text-center" >
                Buy 1 {bridgenetworksymbol} at {usdchangeran} USDT
              </p>
              <div className="switch-banner-section-inner mb-3">
                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-12 text-start">
                      <Select
                        placeholder="Select option..."
                        onChange={rewardset}
                        options={bridgesoption}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="switch-banner-section-inner">
                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-6 col-4 text-start">
                      <span className="switch-banner-section-text-2">
                        Amount You Spend
                      </span>
                    </div>
                    <div className="col text-end">
                      <span className="switch-banner-section-text-2">
                        Balance : <span className="switch-banner-section-text-3">
                          {tokenbalance}
                        </span>
                      </span>
                      <br className="mobile-break" />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-6 text-start">
                      <input type="number" className="form-control form-control-amount" min="0" placeholder="Enter the amount" id="from_amount" onChange={(e) => getfromvaluecontract(e)} value={depositamount} onKeyPress={handleKeyPress} />
                    </div>
                    <div className="col amount-select-section">
                      <div className="row">
                        <div className="col-lg-4 col-4 text-lg-end">
                          <span>
                            <button type="button" className="btn-max" onClick={max}>
                              MAX
                            </button>
                          </span>
                        </div>
                        <div className="col text-end text-lg-center">
                          <span className="amount-select-from">
                            <img src={networklogo} 
                            className={isActive ? logochangestyle["amount-select-image"]: logochangestyle["amount-select-image-change"]} 
                            alt="networklogo" /><span className="amount-select-name">{tokensymbol}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="switch-banner-centericon-section">
                  <div className="row py-2">
                    <div className="col">
                      <HiArrowDown className="centericon-swap" />
                    </div>
                  </div>
                </div>

                <div className="switch-banner-section-inner mb-3">
                  <div className="switch-banner-amount-section">
                    <div className="row">
                      <div className="col-lg-12 text-start">
                        <Select
                          placeholder={<div>select option...</div>}
                          onChange={torewardsetvalue}
                          options={tobridgesoption}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-6 col-4 text-start">
                      <span className="switch-banner-section-text-2">
                        Amount You Buy
                      </span>
                    </div>
                    <div className="col text-end">
                      <span className="switch-banner-section-text-2">
                        Balance : <span className="switch-banner-section-text-3">
                          {bscbal}
                        </span>
                      </span>
                      <br className="mobile-break" />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-12 text-start">
                      <span className="form-control form-control-amount2" min="0" placeholder="0.00" id="to_amount">
                        <input type="number" className="form-control form-control-amount" placeholder="Enter the amount" id="to_amount" onChange={(e) => gettovaluecontract(e)}
                          value={dcxamountvalue} onKeyPress={handleKeyPress} />
                      </span>
                    </div>
                    <div className="col amount-select-section mt-2 mt-lg-0">
                      <div className="row flex-lg-row-reVRS">
                        <div className="col text-end text-lg-end">
                          <span className="amount-select-to">
                            <img src={bridgenetworklogo} 
                            className={isActive ? logochangestyle["amount-select-image"]: logochangestyle["amount-select-image-change"]} 
                            alt="networklogo" /><span className="amount-select-name">{bridgenetworksymbol}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="button-container-1 my-lg-4 my-4">
                  <span className="mas">Buy</span>
                  <button id="work" type="submit" name="Hover" disabled={show} onClick={() => transfer(network)}>
                    BUY
                  </button>
                </div>
                <span style={{ "color": "red" }}>{showError}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Referral;