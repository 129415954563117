import { useState, useEffect } from "react";
import $, { event } from "jquery";
import './css/style.css'
import { HiArrowDown } from 'react-icons/hi';
import Web3 from 'web3'
import { erc20Abi } from './Abi/erc20abi'
import bscimage from './images/bnb.png'
import dusdimages from './images/dusd.png'
import dchainimg from './images/dchainimages.png'
import axios from 'axios';
import Navigate from "./components/navigate";
import config from './config/config';
import { transferbscdcxtoken } from './transferbridge/transferbscdcx';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import copy from "copy-to-clipboard";
import Select from 'react-select';

import logochangestyle from './css/logochange.module.css'



const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '1px dotted pink',
    fontSize: 13,
    color: state.isSelected ? 'white' : 'white',
    backgroundColor: state.isFocused ? "#1b9ffe" : "#031228",
  }),
  control: (base, state) => ({
    ...base,
    color: "red",
    fontSize: 14,
    background: "transparent",
    border: 0,
    // This line disable the blue border
    boxShadow: 'none',
    padding: "6px",
    '&:hover': {
      borderColor: "#ffffff54"
    },

  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    border: '1px solid #3989f92d',
    marginTop: 0
  }),
  menuList: (base, state) => ({
    ...base,
   padding:0,
  })
}





function App() {
    
  const [isActive,setisActive] = useState(false);


  const [depositamount, setdepositamount] = useState();
  const [dropdownvalue, setdropdownvalue] = useState("");

  const [bridgesoption, setbridgesoption] = useState([
    { value: 1, label: "BNB" },
    { value: 2, label: "USDT (BEP20)" }
  ]);


  const [tobridgesoption, settobridgesoption] = useState([
    { value: 1, label: "DCX" },
    { value: 2, label: "DUSD" }
  ]);

  const [todropdownvalue, settodropdownvalue] = useState("");

  const [accountaddress, setaccountaddress] = useState("");
  const [selecttoken, setselecttoken] = useState("0");
  const [tokenbalance, settokenbalance] = useState(0);
  const [tokensymbol, settokensymbol] = useState("");
  const [networklogo, setnetworklogo] = useState(bscimage);

  const [bscbal, setbscbal] = useState(0);
  const [bridgenetworklogo, setbridgenetworklogo] = useState(dchainimg);
  const [bridgenetworksymbol, setbridgenetworksymbol] = useState('');
  const [dcxamountvalue, setdcxamountvalue] = useState();
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState("")

  const [ip, setIP] = useState('');
  const [usdchangeran, setusdchangeran] = useState(0.5);

  const getIP = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
  }



  const torewardsetvalue = async (event) => {
    setisActive(true)
    settodropdownvalue(event.label)
    const userAddress = sessionStorage.getItem("chainaccounts")
    var web3dchainjs = new Web3(config.dchainrpcurl);
    var web3dchainmainjs = new Web3(config.dchainrpcurl);
    if (event.label == "DUSD") {
      setbridgenetworklogo(dusdimages)
      setbridgenetworksymbol("DUSD")
      setusdchangeran(1)
      const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.DUSD_TOKEN_ADDRESS);
      const dchainbalance = await dchaincontract.methods.balanceOf(userAddress).call();
      const balancesdchain = web3dchainjs.utils.fromWei(dchainbalance, 'ether');
      const balancesdchainfixed = Number(balancesdchain).toFixed(8)
      setbscbal(balancesdchainfixed)
      changefromvalues(depositamount, 1)
    }
    else {
      const usd = 0.5;
      setusdchangeran(usd)
      setbridgenetworklogo(dchainimg)
      setbridgenetworksymbol("DCX")
      const dchainbalance = await web3dchainmainjs.eth.getBalance(userAddress);
      const balancesdchain = web3dchainmainjs.utils.fromWei(dchainbalance, "ether");
      const balancesdchainfixed = Number(balancesdchain).toFixed(8)
      setbscbal(balancesdchainfixed)
      
      changefromvalues(depositamount, usd)
     
    }
  }


  const rewardset = async (event) => {
    setdropdownvalue(event.label)
    setisActive(true)
    const reloadCount = sessionStorage.getItem('chainaccounts');
    if(reloadCount !== null) {
      const chainid = await window.ethereum.request({ method: "eth_chainId" });
      if (chainid == config.BNB_CHAINID) {
        if (event.label == "BNB") {
          setdepositamount(0)
          setdcxamountvalue(0)
          settokensymbol("BNB")
          var web3js = new Web3(config.bsctestnetrpcurl);
          const balance = await web3js.eth.getBalance(reloadCount)
          const balanceseth = web3js.utils.fromWei(balance, 'ether');
          const tokenbalance = parseFloat(balanceseth).toFixed(3);
          setselecttoken(event.label)
          settokenbalance(tokenbalance)
        }
        else {
          setdepositamount(0)
          setdcxamountvalue(0)
          settokensymbol("USDT")
          var web3js = new Web3(config.bsctestnetrpcurl);
          const btomfromcontract = new web3js.eth.Contract(erc20Abi, config.BSC_TOKEN_ADDRESS);
          const weibalance = await btomfromcontract.methods.balanceOf(reloadCount).call();
          const balances = web3js.utils.fromWei(weibalance, 'ether');
          const tokenbalance = parseFloat(balances).toFixed(2);
          setselecttoken(event.label)
          settokenbalance(tokenbalance)
        }
      }
      else {
        console.log("wrong network")
      }
    }
    else {
      toast.error("Connect Wallet");
    }

  }

  async function bridges() {
    var web3js = new Web3(window.ethereum);
    const chainid = await window.ethereum.request({ method: "eth_chainId" });
    if (chainid == config.BNB_CHAINID) {
      $('.switch-tabs-section-bsc').css('background', '#1b9ffe');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      setnetworklogo(bscimage)
      const bridgeOptions = [
        { value: 1, label: "BNB" },
        { value: 2, label: "USDT (BEP20)" }
      ]
      setbridgesoption(bridgeOptions)
    }
    else {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3js.utils.toHex(config.BNB_CHAINID) }]
      });
    }
  }

 

  const getmaxvaluecontract = async (event) => {
    const getPriceBNB = await axios.get(config.DEXTRADE_BNB_API);
    const gettrxusdt = getPriceBNB.data.data.last;
    const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
    if (todropdownvalue != "") {
      if (currentchainId == config.BNB_CHAINID && tokensymbol == "BNB") {
        const trnbscdcoin = ((event * gettrxusdt) / usdchangeran)
        setdcxamountvalue(trnbscdcoin.toFixed(5));
      }
      else if (currentchainId == config.BNB_CHAINID && tokensymbol == "USDT") {
        const dcxamount = event / usdchangeran;
        setdcxamountvalue(dcxamount.toFixed(5))
      }
      else {
        console.log("something went wrong")
      }
    }
  }

  const gettovaluecontract = async (event) => {
    event.preventDefault()
    setdcxamountvalue(event.target.value);
    const getPriceBNB = await axios.get(config.DEXTRADE_BNB_API);
    const gettrxusdt = getPriceBNB.data.data.last;
    const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
    if (currentchainId == config.BNB_CHAINID && tokensymbol == "BNB") {
      const trnbscdcoin = ((event.target.value * usdchangeran) / gettrxusdt)
      let fromamount = trnbscdcoin.toFixed(5);
      setdepositamount(fromamount.toString())
        setShow(false)
        setShowError()
    }
    else if (currentchainId == config.BNB_CHAINID && tokensymbol == "USDT") {
      const dcxamount = event.target.value * usdchangeran;
      let fromamountusdt = dcxamount.toFixed(5);
      setdepositamount(fromamountusdt.toString());
        setShow(false)
        setShowError()
    }
    else {
      console.log("Something went wrong")
    }
  }


  const changefromvalues = async (values, usdrange) => {
    if (usdrange !== undefined) {
      const getPriceBNB = await axios.get(config.DEXTRADE_BNB_API);
      const gettrxusdt = getPriceBNB.data.data.last;
      const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
      if (currentchainId == config.BNB_CHAINID && tokensymbol == "BNB") {
        const trnbscdcoin = ((values * gettrxusdt) / usdrange)
        setdcxamountvalue(trnbscdcoin.toFixed(5));
          setShow(false)
          setShowError()
      }
      else if (currentchainId == config.BNB_CHAINID && tokensymbol == "USDT") {
        const dcxamount = values / usdrange;
        setdcxamountvalue(dcxamount.toFixed(5))
          setShow(false)
          setShowError()
      }
      else {
        console.log("something went wrong")
      }
    }
  }

  const getfromvaluecontract = async (event) => {
    event.preventDefault()
    setdepositamount(event.target.value)
    if(todropdownvalue !== ""){
      changefromvalues(event.target.value, usdchangeran);
    }
  }


  const transfer = async () => {
    var web3js = new Web3(config.dchainrpcurl);
    const balance = await web3js.eth.getBalance(config.DCX_CONTRACT_ADDRESS)
    const reloadCount = sessionStorage.getItem('chainaccounts');
    if(reloadCount !== null) { 
      if(dropdownvalue == "") {
        toast.error("Select BNB or USDT...");
      }
      else {
        if(todropdownvalue == "DUSD") {
          if (dcxamountvalue === 0) {
            toast.error("Please enter value in amount...");
          } 
          else {
            const accounts = sessionStorage.getItem("chainaccounts")
            const chainid = await window.ethereum.request({ method: "eth_chainId" });
            if (chainid == config.BNB_CHAINID) {
              const setdatas = {
                useraccount: accounts,
                deposits: depositamount,
                tokenselect: selecttoken,
                ipaddr: ip,
                toamountchange: dcxamountvalue,
                totokenvalue: "DUSD"
              };
              await transferbscdcxtoken(setdatas);
            }
            else {
              console.log("wrong network")
            }
          }
        }
        else if(todropdownvalue == "DCX") {
          const balancesdcxs = web3js.utils.toWei(dcxamountvalue.toString(), 'ether');
          if(parseInt(balancesdcxs) <= parseInt(balance)) {
            if (dcxamountvalue === 0) {
              toast.error("Please enter value in amount...");
            } 
            else {
              const accounts = sessionStorage.getItem("chainaccounts")
              const chainid = await window.ethereum.request({ method: "eth_chainId" });
              if (chainid == config.BNB_CHAINID) {
                const setdatas = {
                  useraccount: accounts,
                  deposits: depositamount,
                  tokenselect: selecttoken,
                  ipaddr: ip,
                  toamountchange: dcxamountvalue,
                  totokenvalue: "DCX"
                };
                await transferbscdcxtoken(setdatas);
              }
              else {
                console.log("wrong network")
              }
            }
          }
          else {
            Swal.fire({
              icon: "error",
              background: "#041325",
              border: "1px solid #00e5ff21",
              color: "#ffffff",
              html: `
                <div>
                  <span>Insufficient balance in Liquidity pool, kindly reach:</span>
                  <br />
                  <br />
                  <a id="copyButton">
                    info@d-ecosystem.io <i class="far fa-copy"></i>
                  </a>
                </div>
              `,
            })
            document.getElementById("copyButton").addEventListener("click", function() {
              copyContentToClipboard("info@d-ecosystem.io");
            });
          }
        }
        else {
          toast.error("Select DCX or DUSD");
        }
      }
    }
    else {
      toast.error("Connect Wallet");
    }
  }


  const copyContentToClipboard = async(content) => {
    copy(content);
    toast.success("Copied");
  }

  //max value
  const max = async () => {
    const depositpercent = ((tokenbalance * 99) / 100).toString();
    setdepositamount(depositpercent)
    getmaxvaluecontract(depositpercent)
  }



  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };

  useEffect(() => {
    setbscbal(0);
    settokenbalance(0);
    setShow(false)
    setShowError()
    if (window.ethereum) {
      const reloadCount = sessionStorage.getItem('chainaccounts');
      if(reloadCount !== null) {
        setaccountaddress(reloadCount)
        getIP()
        bridges()
      }
    }
    else {
      openInNewTab("https://metamask.io/download/")
      toast.warn("Check Metamask is Installed", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, []);

 


  return (
    <div className="App">
      <Navigate useraddress={accountaddress}  />
      <section className="py-lg-5 py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 switch-tabs-section">
              <div className="btn-group switch-tabs-btn-group">
                <button type="button"  className="btn switch-tabs-section-bsc">
                  <img className="network-image" src={bscimage} alt="BSC Network" />BUY DCX with BNB or USDT BEP 20
                </button>
              </div>
            </div>
            <div className="col-lg-8 switch-banner-section pb-lg-5 mt-4">
              <p className="switch-banner-section-text-1 text-center">
                Buy 1 {bridgenetworksymbol == ""? "DCX":bridgenetworksymbol} at {usdchangeran} USDT
              </p>

              <div className="switch-banner-section-inner mb-3">
                <div className="switch-banner-amount-section p-0 ">
                  <div className="row">
                    <div className="col-lg-12 text-start">
                      <Select
                        placeholder={<div>select option...</div>}
                        onChange={rewardset}
                        options={bridgesoption}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="switch-banner-section-inner">
                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-6 col-4 text-start">
                      <span className="switch-banner-section-text-2">
                        Amount You Spend
                      </span>
                    </div>
                    <div className="col text-end">
                      <span className="switch-banner-section-text-2">
                        Balance : <span className="switch-banner-section-text-3">
                          {tokenbalance}
                        </span>
                      </span>
                      <br className="mobile-break" />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-6 text-start">
                      <input type="number" 
                        className="form-control form-control-amount" min="" 
                        placeholder="Enter the amount" 
                        id="from_amount" 
                        onChange={(e) => getfromvaluecontract(e)} 
                        value={depositamount}
                        onKeyPress={(event) => {
                          const keyCode = event.which || event.keyCode;
                          const keyValue = String.fromCharCode(keyCode);
                          if (!/^[0-9.\b]+$/.test(keyValue)) {
                            event.preventDefault();
                          }
                        }}
                        onWheel={(e) => document.activeElement.blur()} 
                      />
                    </div>
                    <div className="col amount-select-section">
                      <div className="row">
                        <div className="col-lg-4 col-4 text-lg-end">
                          <span>
                            <button type="button" className="btn-max" onClick={max}>
                              MAX
                            </button>
                          </span>
                        </div>
                        <div className="col text-end text-lg-center">
                          <span className="amount-select-from">
                            <img src={networklogo} 
                              className={isActive ? logochangestyle["amount-select-image"]: logochangestyle["amount-select-image-change"]} alt="networklogo" />
                              <span className="amount-select-name">{tokensymbol}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="switch-banner-centericon-section">
                  <div className="row py-2">
                    <div className="col">
                      <HiArrowDown className="centericon-swap" />
                    </div>
                  </div>
                </div>

                <div className="switch-banner-section-inner mb-3">
                  <div className="switch-banner-amount-section p-0">
                    <div className="row">
                      <div className="col-lg-12 text-start">
                        <Select
                          placeholder={<div>select option...</div>}
                          onChange={torewardsetvalue}
                          options={tobridgesoption}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-6 col-4 text-start">
                      <span className="switch-banner-section-text-2">
                        Amount You Buy
                      </span>
                    </div>
                    <div className="col text-end">
                      <span className="switch-banner-section-text-2">
                        Balance : <span className="switch-banner-section-text-3">
                          {bscbal}
                        </span>
                      </span>
                      <br className="mobile-break" />
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-12 text-start">
                      <span className="form-control form-control-amount2" placeholder="" id="to_amount">
                        <input
                          className="form-control form-control-amount"
                          type="text"
                          id="to_amount"
                          value={dcxamountvalue}
                          placeholder="Enter the amount"
                          onChange={(e) => gettovaluecontract(e)}
                          onKeyPress={(event) => {
                            const keyCode = event.which || event.keyCode;
                            const keyValue = String.fromCharCode(keyCode);

                            // Allow only numeric values (0-9) and the backspace key (8)
                            if (!/^[0-9.\b]+$/.test(keyValue)) {
                              event.preventDefault();
                            }
                          }}
                        ></input>
                      </span>
                    </div>
                    <div className="col amount-select-section mt-2 mt-lg-0">
                      <div className="row flex-lg-row-reVRS">
                        <div className="col text-end text-lg-end">
                          <span className="amount-select-to">
                            <img src={bridgenetworklogo} 
                              className={isActive ? logochangestyle["amount-select-image"]: logochangestyle["amount-select-to-image-change"] } 
                              alt="networklogo" />
                              <span className="amount-select-name">{bridgenetworksymbol}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="button-container-1 my-lg-4 my-4">
                  <span className="mas">BUY</span>
                  <button id="work" type="button" name="Hover" disabled={show} onClick={() => transfer()}>
                    BUY
                  </button>
                </div>
                <span style={{ "color": "red" }}>{showError}</span>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}


export default App;