let config = {};
let environment = "live";
if (environment === "local") {
  config = {
    BNB_CHAINID : "0x61",
    REACT_APP_URL : "https://stagging-dcx.d-ecosystem.io/",
    APP_API_URL : 'https://stagging-api-dcx.d-ecosystem.io/',
    BSC_BRIDGE_ADDRESS:"0xEc1adC657E72Ff8c28552C222DA74680C14c6e45",
    bsctestnetrpcurl:"https://bsc-testnet.publicnode.com",
    dchainrpcurl : "https://mainnetcoin.d-ecosystem.io",
    BSC_TOKEN_ADDRESS:"0x1EaE2855cf10e7b56B523C89cFBb1F4CE6e3939e",
    DCX_CONTRACT_ADDRESS:"0xB1F366b980Ed61581F07EA8e79c7c1ad2B1680b5",
    DUSD_TOKEN_ADDRESS:"0xBAcfb47E7CA48FF2991b4939C5B31088538F3C7C",
    DEXTRADE_API:"https://api.dex-trade.com/v1/public/ticker?pair=DCXUSDT",
    DEXTRADE_BNB_API:"https://api.dex-trade.com/v1/public/ticker?pair=BNBUSDT"
  };
} else {
  config = {
    BNB_CHAINID : "0x38",
    REACT_APP_URL : "https://dcx.d-ecosystem.io/",
    APP_API_URL : 'https://api-dcx.d-ecosystem.io/',
    BSC_BRIDGE_ADDRESS:"0xb5a40bd33ab8a9bf4212a19c4f480fd8ea521542",
    bsctestnetrpcurl:"https://bsc.publicnode.com",
    dchainrpcurl : "https://mainnetcoin.d-ecosystem.io/",
    BSC_TOKEN_ADDRESS:"0x55d398326f99059fF775485246999027B3197955",
    DCX_CONTRACT_ADDRESS:"0x9db351E99a0a9aa07C07Ed065012c4dD8F3722fc",
    DUSD_TOKEN_ADDRESS:"0x31bb32680709907B4a2cD0F0BAf718D73C4d6B62",
    DEXTRADE_API:"https://api.dex-trade.com/v1/public/ticker?pair=DCXUSDT",
    DEXTRADE_BNB_API:"https://api.dex-trade.com/v1/public/ticker?pair=BNBUSDT"
  };
}

export default config;