import Web3 from "web3";
import { erc20Abi } from "../Abi/erc20abi";
import { homebridgeAbi } from "../Abi/homebridgeabi";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../config/config";


export const transferbscdcxtoken = async (data = {}) => {
  var web3js = new Web3(window.ethereum);
  const accounts = data.useraccount;
  let accountlower = accounts.toLowerCase();
  const depositamount = data.deposits;
  const selecttoken = data.tokenselect;
  const ip = data.ipaddr;
  let toamountchange = data.toamountchange;
  let totokenvalues = data.totokenvalue;
  const btomfromcontract = new web3js.eth.Contract(erc20Abi,config.BSC_TOKEN_ADDRESS);
  const bscbridgecontract = new web3js.eth.Contract(homebridgeAbi,config.BSC_BRIDGE_ADDRESS);
  const zeroaddress = "0x0000000000000000000000000000000000000000" 

  const transfertoken = web3js.utils.toWei(depositamount, "ether");
  if (selecttoken == "USDT (BEP20)") {
    await btomfromcontract.methods.approve(config.BSC_BRIDGE_ADDRESS, transfertoken).send({ from: accounts });
    Swal.fire({
      icon: "warning",
      background: "#041325",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Transaction is Processing",
      html: "Do Not Refresh or Reload the Page",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    
    const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(zeroaddress,transfertoken,totokenvalues)
      .send({
        from: accounts,
      }).on('error', function(error, receipt) { 
        if(error.code == 4001) {
          window.location.reload()
        }
    });
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      await axios({
        method: "POST",
        url: `${config.APP_API_URL}user_create`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: {
          userAddress: accountlower,
          ip_address: ip,
          first_purchase_amt: transferfn.events.tokenTransfer.returnValues.amount,
          currency_type: "USDT",
          first_purchase_dcx_amt : toamountchange
        },
      })
      axios({
          method: "POST",
          url: `${config.APP_API_URL}getuserhash`,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          data:{
            referaddress : zeroaddress,
            amount: transfertoken,
            fromChain: "USDT",
            toChain: totokenvalues,
            userAddress: accountlower,
            toamount : toamountchange
          }
        }).then(async (userData) => {
          Swal.fire({
          icon: "success",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction confirmed",
          html:
            "Your transaction hash is:<br/><br/>" +
            `<a href=${
              "https://mainnet-explorer.d-ecosystem.io/tx/" + userData.data.data
            }  target="_blank" style="color:#03a2ff;">${userData.data.data}</a>
              <br/><br/>Refer & Earn 5% Commission`,
        }).then(function () {
          window.location = "/leaderboard";
        });
        })
        .catch((err) => window.location = "/leaderboard"); 
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  } else {
    Swal.fire({
      icon: "warning",
      background: "#041325",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Transaction is Processing",
      html: "Do Not Refresh or Reload the Page",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(zeroaddress,0,totokenvalues).send({
        from: accounts,
        value: web3js.utils.toWei(depositamount, "ether"),
      }).on('error', function(error, receipt) { 
        if(error.code == 4001) {
          window.location.reload()
        }
    });
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      await axios({
        method: "POST",
        url: `${config.APP_API_URL}user_create`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: {
          userAddress: accountlower,
          ip_address: ip,
          first_purchase_amt: transferfn.events.tokenTransfer.returnValues.amount,
          currency_type: "BNB",
          first_purchase_dcx_amt : toamountchange
        },
      })
      axios({
        method: "POST",
        url: `${config.APP_API_URL}getuserhash`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data:{
          userAddress: accountlower,
          referaddress: zeroaddress,
          amount: transfertoken,
          fromChain: "BNB",
          toChain: totokenvalues,
          toamount: toamountchange
        }
      }).then(async (userData) => {
        Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${
            "https://mainnet-explorer.d-ecosystem.io/tx/" + userData.data.data
          }  target="_blank" style="color:#03a2ff;">${userData.data.data}</a>
            <br/><br/>Refer & Earn 5% Commission`,
      }).then(function () {
        window.location = "/leaderboard";
      });
      })
      .catch((err) => window.location = "/leaderboard"); 

    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  }
};